.gw__help-and-support-title {
  font-size: 45px;
  font-weight: $font-weight-bold;
  line-height: 61px;
  margin-bottom: 0;
}

.gw__help-and-support-link {
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  color: $green;
  cursor: pointer;
}

.gw__help-and-support-subtext {
  font-size: 14px;
  line-height: 17px;
}

.gw__help-and-support-select-icon {
  font-size: 30px;
  color: $green !important;
  transition: transform 0.15s ease-in-out;
}

.Mui-expanded {
  .gw__help-and-support-select-icon {
    transform: rotate3d(0, 0, 1, 180deg);
  }  
}

.gw__help-and-support-section-title {
  font-size: 30px;
  font-weight: $font-weight-light;
  line-height: 41px;
  margin: 0;
}

.gw__help-and-support-section-content {
  font-size: 14px;
  font-weight: $font-weight-light;
  line-height: 25px;
}

.gw__help-and-support-bold {
  font-weight: $font-weight-bold;
}

// Ensures there's no padding on the drawer panel sides
div.MuiButtonBase-root {
  padding: 0;
}

// Removes padding on the panel sides
div.MuiExpansionPanelDetails-root {
  padding: 8px 0;
}

@media screen and (max-width: 991px) {
  .gw__help-and-support-title {
    font-size: 18px;
    line-height: 15px;
  }

  .gw__help-and-support-subtext {
    font-size: 11px;
    line-height: 15px;
  }

  .gw__help-and-support-link {
    font-size: 11px;
    line-height: 15px;
  }

  .gw__help-and-support-section-icon {
    font-size: 14px;
  }

  .gw__help-and-support-section-title {
    font-size: 14px;
    line-height: 15px;
  }

  .gw__help-and-support-section-content {
    font-size: 11px;
    line-height: 15px;
  }
}
