.vc__icon-hidden {
  width: 0;
  opacity: 0;
  transition: all 0.3s;

  svg {
    overflow: hidden;
    vertical-align: middle;
  }

  &.transition {
    opacity: 1;
    width: 28px;
  }
}

.sidebar {
  height: 100vh;
  width: 260px;
  position: fixed;
  z-index: 1;
  right: 0;
  top: 0;
  background-color: white;
  padding: 0;
  list-style: none;
  border-left: 1px solid $light-gray;
  margin: 0;
  margin-top: $navHeight;

  .sidebar-scroll {
    height: 93%;
    overflow-y: scroll;
  }

  .question-section {
    text-transform: uppercase;
    color: $white;
    line-height: 16px;
    font-size: 10px;
    font-weight: 500;
    font-family: $standard;
    padding: .25rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid $white;

    &.specific {
      margin-top: -1rem;
    }
  }

  li {
    padding: 15px;
    padding: 25px 15px 0 15px;
    text-transform: uppercase;
    line-height: 16px;
    font-size: 10px;
    color:$dark-gray;
    cursor: pointer;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 5px;
      height: 0%;
      background-color: $green;
      transition: height 0.3s ease-out;
    }

    &.done {
      &::before {
        height: 100%;
      }
    }

    &.current {
      color: $black;
    }

    hr {
      margin-top: 25px;
      margin-bottom: 0;
      border: 0;
      border-top: 1px solid $lighter-gray;
      box-sizing: content-box;
      height: 0;
      overflow: visible;
    }

    &:hover {
      background: $lighter-gray;
    }

    &.finished {
      background: $lighter-gray !important;
      cursor: auto;
    }

    &.visa {
      &:hover{
        background: none;
      }
    }

    &.disabled {
      color: $light-gray;
      cursor: auto;

      &:hover{
        background: none;
      }
    }

    .step {
      display: flex;

      h6 {
        margin-top: 0;
        margin-bottom: 0;
        display: inline-block;
        letter-spacing: 1.50px;
        font-family: $standard;
        font-size: 10px !important;
      }

      .current {
        font-weight: 900;
      }

      .checkmark-icon {
        fill: $green;
        margin-top: -5px;
        margin-right: 10px;
      }

      .lock-icon {
        color: $dark-gray !important;
        font-size: 24px !important;
        margin-right: 10px !important;
        &:before {
          line-height: 20px;
        }
      }
    }
  }

  li:first-child {
    margin-top: 0;
  }
}

@media only screen and (max-width: 1200px){
  .sidebar {
    width: 220px;
  }
}

@media only screen and (max-width: 768px){

  .sidebar {
    display: none;
  }

  .reset-destination-link{
    font-size: 0.75rem;
  }

  .reset-modal{
    width: 90%;
  }
}

@media only screen and (max-width: 991px){
  .sidebar {
    margin-top: $navHeightMobile;
  }
}
