.gw__existing-visa-modal {
  height: 90%;
  min-width: 75%;

  .modal-body {
    padding-left: 100px;
    padding-right: 100px
  }
}

.gw__existing-visa-container {
  height: 100%;
}

.gw__existing-visa-section-title {
  font-size: 16px;
  font-weight: $font-weight-normal;
  letter-spacing: 0.08rem;
}

.gw__existing-visa-title {
  font-size: 40px;
  font-weight: $font-weight-normal;
  line-height: 50px;
  letter-spacing: -1.13px;
}

.gw__existing-visa-header {
  font-size: 36px;
  font-weight: $font-weight-normal;
}

.gw__existing-visa-question {
  font-size: 22px;
  font-weight: $font-weight-normal;
}

.gw__existing-visa-subtext {
  font-size: 16px;
  font-weight: $font-weight-light;

  a:link, a:visited, a:active {
    text-decoration: none;
    color: $green;
    cursor: pointer;
  }
}

.gw__warning-icon {
  font-size: 5rem !important;
  color: $green;
}

.gw__warning-icon-yellow {
  font-size: 6rem !important;
  color: $warning;
  line-height: 1;
}

// The inside of the radio button, the ending "layer-" changes number so we use a wildcard so it doesn't depend on the specific number
svg[class^="MuiSvgIcon-root PrivateRadioButtonIcon-layer-"] {
  color: $green;
}

// The outside color of the radio button
span.MuiRadio-colorPrimary.Mui-checked {
  color: $dark-gray;
}

@media screen and (max-width: 991px) {
  .gw__existing-visa-modal {
    .modal-body {
      padding-left: 50px;
      padding-right: 50px
    }
  }
}
