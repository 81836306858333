.fadeInUp {
  height: 100%;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
  animation-fill-mode: both;
  animation-delay: 0.2s;
}

.fadeInRight {
  margin-top: 2rem;
  position: absolute !important;
  opacity: 0;
  right: -300px;
  animation: fadeIn 2s forwards, slideRight 0.8s  forwards;
  animation-fill-mode: both;
  animation-delay: 0.2s;
}

.slideUpDown {
  transform: translateY(-100%);
  width: 100%;
  animation: slidedown 0.4s forwards;
  animation-delay: 1s;
}

@keyframes fadeIn {
  from {
    transform: translate3d(0, 50px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes slideRight {
  100% { right: 0; }
}

@keyframes slideUp {
  100% { bottom: 0; }
}

@keyframes slidedown {
  100% { transform: translateY(0); }
}

@keyframes spin {
  to { 
    transform: rotate(360deg);
  }
}
