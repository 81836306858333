.gw__summary-container {
  width: 75%;
}

.gw__summary-title {
  color: $black;
  font-size: 45px;
  font-weight: $font-weight-bold;
  line-height: 61px;
  margin: 0;
}

.gw__summary-label {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: $font-weight-extra-bold;
  line-height: 22px;
  color: $green;
  letter-spacing: 1.25px;
  padding-left: 0.5rem;

  &--disabled {
    @extend .gw__summary-label;
    color: $gray;
  }
}

.MuiSnackbarContent-message {
  width: 100%;
}

.gw__snackbar-close {
  display: flex;
  justify-content: space-between;
}

.gw__snackbar-close-icon {
  cursor: pointer;
}

.gw__snackbar-warning-content {
  background-color: $green;
  font-weight: $font-weight-light;
}

.gw__results-copied-check {
  color: $green;
  font-size: 1rem;
  margin-right: 0.25rem;
}

div.gw__summary-padding {
  padding-left: 0;
}

@media screen and (max-width: 991px) {
  .gw__summary-title {
    font-size: 26px;
    line-height: 25px;
    margin: 0;
  }

  div.gw__summary-padding {
    padding-left: 15px;
  }
}