.alert-success {
  background-color: $green;
  color: $black;

  .close {
    color: $black;
  }
}

.alert-warning {
  background-color: $yellow;
  color: $black;

  .close {
    color: $black;
  }
}

.alert-danger {
  background-color: $red;
  color: $white;

  .close {
    color: $white;
  }
}
