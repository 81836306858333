.gw__session-expired-background {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  background-color: $white;
  opacity: 0.5;
  z-index: 1400;
  padding: 1rem;
}

.gw__session-expired-message-container {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  z-index: 1500;
  position: relative;
  height: 100%;
  padding-top: 4rem;
  width: 100vw;
}

.gw__session-expired-message {
  background-color: $white;
  border: 1px solid $lighter-gray;
  box-shadow: $standard-dropshadow;
  border-radius: 2rem;
  padding: 6rem;
  font-family: $headings;
  color: $gray;
  letter-spacing: 2px;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.gw__fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}