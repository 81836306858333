// The landing page container, holding the background image
.gw__landing-page-container {
  height: calc(100vh - #{$navHeight});
  background-image: url('/images/homepage-desktop.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 700px;
}

.gw__landing-page-dropdown-container {
  width: 100vw;
}

// GoWork TOS Modal
.modal-dialog {
	max-width: 70% !important;
}

.thumb-vertical {
	color: grey;
}

h5 {
	letter-spacing: 0.5px;
	font-size: 0.80rem !important;
}

.tos_link {
	text-decoration: none;
	color: $green;
	letter-spacing: 0.5px;
}

.tos_paragraph {
	width: 95% !important;
	font-size: 0.85rem;
	line-height: 20px;
	font-family: "Open Sans", sans-serif;
}


.gw__tos {
  min-height: 75vh !important;
  height: 75vh !important;
  width: 100% !important;

  ul {
    padding-inline-start: 30px !important;
  }
  
  li {
    width: 90% !important;
    font-size: 0.85rem;
    line-height: 20px;
    font-family: "Open Sans", sans-serif;
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

.gw__tos-table {
  // border: 1px solid black !important;

  .gw__tos-table-col {
    width: 13rem;
    border: 1px solid black;
    padding: 0.5rem;
    // border-right: 1px solid black;
  }

  .gw__tos-table-content {
    border: 1px solid black;
    padding: 0.5rem;
  }
}

.gw__landing-page-tos-title {
	font-size: 38px;
	letter-spacing: -0.11px;
	line-height: 75px;
	text-align: left;
	font-weight: 900;
}

.landing-modal-body {
	overflow: visible !important;
}

.gw__tos-footer {
  margin: auto !important;
}

// The GoWork title on the landing page
.gw__landing-page-title {
  font-size: 88px;
  letter-spacing: -0.11px;
  line-height: 75px;
  text-align: center;

  // The bolded 'Go'
  .gw__landing-page-title-go {
    font-weight: $font-weight-bold;
  }

  // The lightly bolded 'Work'
  .gw__landing-page-title-work {
    font-weight: $font-weight-light;
  }

  .gw__landing-page-logo{
    display: block;
    margin: 0 auto;
    height: 80px;
  }
}

.gw__landing-page-description-container {
  display: flex;
  justify-content: center;
}

// The GoWork description on the landing page
.gw__landing-page-description {
  width: 550px;
  color: $darker-gray;
  font-size: 24px;
  font-weight: $font-weight-light;
  line-height: 30px;
  text-align: center;
}

.gw__retry-login {
  color: $red;
  font-size: 16px;
  cursor: pointer;
  transition: transform .5s ease;

  &:hover {
    transform: scale(1.1);
  }
}

.gw__destination-react-select-container {
  width: 30%;
  max-width: 40rem;
  min-width: 26rem;
  margin-bottom: 1rem;
}
.gw__destination-react-select__control {
  font-size: 1.6rem;
}
// The border of the clickable area
.gw__destination-react-select__control.css-yk16xz-control,
.gw__destination-react-select__control--is-focused.css-1pahdxg-control,
.gw__destination-react-select__control--menu-is-open.css-1pahdxg-control {
  border-color: $green;
  border-radius: 0%;
  height: 4rem;

  &:hover {
    border-color: $green;
  }
}

// Hide the indicator separator
.gw__destination-react-select__indicator-separator {
  display: none;
}

// The placeholder text for react-select
.gw__destination-react-select__placeholder {
  color: $light-gray;
  font-size: 1.6rem;
  font-weight: 300;
}

// The box shadow on the container when something is selected
.gw__destination-react-select__control.gw__destination-react-select__control--is-focused.css-1pahdxg-control {
  box-shadow: 0 0 0 1px $green;
}

// The value shown when selected
.gw__destination-react-select__single-value {
  color: $dark-gray;
  font-size: 1.5rem;
  font-weight: 300;
}

// No value selected yet, dropdown options when hovering over them
.gw__destination-react-select__option.gw__destination-react-select__option--is-focused.css-1n7v3ny-option {
  background-color: $lighter-gray;
  color: $dark-gray;
}

// No value selected yet, dropdown options when not hovering over them
.gw__destination-react-select__option.css-yt9ioa-option {
  background-color: $white;
  color: $dark-gray;
}

// The dropdown options when not hovering over them
.gw__destination-react-select__option.gw__destination-react-select__option--is-selected.css-9gakcf-option {
  background-color: $white;
  color: $dark-gray;
  cursor: pointer;
}

// The dropdown options when you are hovering over them
.gw__destination-react-select__option.gw__destination-react-select__option--is-focused.gw__destination-react-select__option--is-selected.css-9gakcf-option {
  background-color: $lighter-gray;
  color: $dark-gray;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .gw__landing-page-container {
    height: calc(100vh - #{$navHeightMobile});
    background-image: url('/images/homepage-ipad.png');
    background-size: cover;
  }
}

/* On screens that are 991px or less */
@media screen and (max-width: 767px) {  

  .gw__landing-page-container {
    min-height: 300px;
  }

  .gw__landing-page-title {
    font-size: 44px;
    line-height: 50px;
  }

  .gw__landing-page-description {
    width: 70%;
    text-align: center;
    font-size: 16px;
  }

  .gw__destination-react-select-container {
    width: 100%;
    max-width: 300px;
    min-width: 160px;
    margin-bottom: 1rem;
  }

  // The border of the clickable area
  .gw__destination-react-select__control.css-yk16xz-control,
  .gw__destination-react-select__control--is-focused.css-1pahdxg-control,
  .gw__destination-react-select__control--menu-is-open.css-1pahdxg-control {
    border-color: $green;
    border-radius: 0%;
    height: 4rem;

    &:hover {
      border-color: $green;
    }
  }

  // Hide the indicator separator
  .gw__destination-react-select__indicator-separator {
    display: none;
  }

  // The placeholder text for react-select
  .gw__destination-react-select__placeholder {
    color: $light-gray;
    font-size: 16px;
    font-weight: 300;
  }

  // The box shadow on the container when something is selected
  .gw__destination-react-select__control.gw__destination-react-select__control--is-focused.css-1pahdxg-control {
    box-shadow: 0 0 0 1px $green;
  }

  // The value shown when selected
  .gw__destination-react-select__single-value {
    color: $dark-gray;
    font-size: 16px;
    font-weight: 300;
  }

  // No value selected yet, dropdown options when hovering over them
  .gw__destination-react-select__option.gw__destination-react-select__option--is-focused.css-1n7v3ny-option {
    background-color: $lighter-gray;
    color: $dark-gray;
  }

  // No value selected yet, dropdown options when not hovering over them
  .gw__destination-react-select__option.css-yt9ioa-option {
    background-color: $white;
    color: $dark-gray;
  }

  // The dropdown options when not hovering over them
  .gw__destination-react-select__option.gw__destination-react-select__option--is-selected.css-9gakcf-option {
    background-color: $white;
    color: $dark-gray;
    cursor: pointer;
  }

  // The dropdown options when you are hovering over them
  .gw__destination-react-select__option.gw__destination-react-select__option--is-focused.gw__destination-react-select__option--is-selected.css-9gakcf-option {
    background-color: $lighter-gray;
    color: $dark-gray;
    cursor: pointer;
  }
}

.gw__destination-react-select__indicator {
  .gw__landing-page-select-icon {
    color: $gray;
    transition: transform 0.15s ease-in-out;
  }
}

.gw__destination-react-select__control--menu-is-open {
  .gw__destination-react-select__indicator {
    .gw__landing-page-select-icon {
      transform: rotate3d(0, 0, 1, 180deg);
    }
  }
}

/* On mobile screens */
@media screen and (max-width: 500px) {
  .gw__landing-page-container {
    background-image: url('/images/homepage-mobile.png');
    background-size: cover;
  }

  .gw__landing-page-description {
    width: 80%;
    text-align: center;
    font-size: 16px;
  }
}

@media screen and (min-width: 1400px) {
  .gw__landing-page-container {
    background-image: url('/images/homepage.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }

  .gw__landing-page-description {
    width: 658px;
    color: $darker-gray;
    font-size: 24px;
    font-weight: $font-weight-light;
    line-height: 30px;
    text-align: center;
  }
}

