// Buttons
$green: #8DDE43;
$green-hover: #86C44B;
$dark-green: #078346;
$darkest-green: #22462A;

// Text
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

//fonts
$standard: 'Open Sans', sans-serif;
$headings: 'Open Sans', sans-serif;
$headings-bold: Product-Sans-Bold;
$headings-font-weight: 800;

//brand colors
$dark-gray: #757575;
$light-gray: #B8B8B8;
$white: #ffffff;
$lighter-gray:#f0f0f0;
$lightest-gray:#f9f9f9;
$black: #000000;
$gray:#858585;
$darker-gray: #4e4e4e;
$navbar-gray: #63666A;

$red: #E53030;
$yellow: #FFCC00;

// Custom spacing
$space80: 80px;
$navHeight: 70px;
$navHeightMobile: 60px;

//card drop shadow
$standard-dropshadow: 0 1px 1px 0 rgba(0,0,0,0.08), 0 2px 1px -1px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.10) !important;

// Bootstrap overrides
$primary: $green;
$danger: $red;
$warning: $yellow;
$text-muted: $gray;