.table {
  border-spacing: 0;
  font-size: 14px;

  thead th {
    border-top: 0;
    font-weight: $font-weight-extra-bold;
    color: $primary;
  }

  tbody {
    th, td {
      border-top: 0;
      border-bottom: 2px solid #dee2e6;
    }
  }
}