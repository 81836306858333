@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700;800&display=swap');

body {
  font-family: 'Open Sans';
  margin: 0;
  overscroll-behavior: none;
}

@import './styles/variables.scss';
@import './styles/icons';
@import './styles/animations';
@import './styles/assessment-question.scss';
@import './styles/existing-visa.scss';
@import './styles/help-and-support.scss';
@import './styles/horizontal-stepper.scss';
@import './styles/landing-page.scss';
@import './styles/Reporting/Reporting.scss';
@import './styles/results.scss';
@import './styles/sidebar.scss';
@import './styles/summary.scss';
@import './styles/SessionExpired/SessionExpired.scss';
@import './styles/announcement.scss';

@import './styles/bootstrap-customisation/imports.scss';

*, *::before, *::after {
  box-sizing: border-box;
  font-family: 'Open Sans';
}

.gw__app-container {
  height: 100vh;
  width: 100%;
}

.__noPrint__{
  display: none;
}

.gw__app__main {
  position: relative;
  padding-top: $navHeight;
  min-height: calc(100vh - #{$navHeight});
}

.gw__loading-spinner {
  color: $green;
}

.MuiCircularProgress-root{
  color: $green !important;
}

.loader {
  &.results {
    height: 90vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.font-weight-extra-bold {
  font-weight: $font-weight-extra-bold;
}

.gw__disclaimer{
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-style: italic;
  color: grey;
  text-align: justify;
}

/* On screens that are 991px or less, set the background color to olive */
@media screen and (max-width: 991px) {
  .gw__app-container {
    width: 100vw;
  }

  .gw__app__main {
    position: relative;
    padding-top: $navHeightMobile;
    min-height: calc(100vh - #{$navHeightMobile});
  }
}
