.gw__results-box {
  position: relative;
  width: 100%;

  hr {
    border-top: 1px solid $light-gray;
  }
}

.gw__results-assessment-id {
  font-size: 12px;
  font-weight: $font-weight-extra-bold;
  line-height: 22px;

  span {
    font-weight: $font-weight-light;
  }
}

.gw__results-icon {
  font-size: 1.5rem;
  color: black;
  margin-right: 8px;
}

.gw__results-title {
  font-size: 45px;
  font-weight: $font-weight-extra-bold;
  line-height: 61px;
  margin-top: 0;
  margin-bottom: 0;
}

.gw__results-subtext{
  width: 90%;
  font-size: 16px;
  font-weight: $font-weight-light;
  line-height: 22px;
  padding: 1.5em 0;

  span {
    font-weight: $font-weight-bold;
  }

  a {
    text-decoration: none;
    color: $green;
    cursor: pointer;
  }

  a:hover {
    color: $green-hover;
  }
}

.gw__results-review-inputs {
  font-size: 16px;
  font-weight: $font-weight-bold;
  line-height: 22px;

  span {
    color: $green;
    font-size: 12px;
  }
}

.gw__results-success {
  color: $green;
  height: 60px !important;
  width: 60px !important;
  margin-left: 10px;
}

.gw__results-failure {
  color: $danger;
  height: 60px !important;
  width: 60px !important;
  margin-left: 10px;
}

.gw__results-warning {
  color: $warning;
  height: 60px !important;
  width: 60px !important;
  margin-left: 10px;
}

.gw__results__multi-permit-link {
  font-weight: $font-weight-extra-bold;
  text-decoration: none;
  color: $green;
  white-space: nowrap;
  cursor: pointer;

  .MuiSvgIcon-root {
    height: 0.7em;
    width: 0.7em;
    vertical-align: sub;
  }
}

#alternatives {
  .MuiExpansionPanelSummary-root {
    transition: background-color 0.2s ease-in-out;
  }
  &.highlight {
    .MuiExpansionPanelSummary-root {
      background-color: greenyellow;
    }
  }
}

.gw__results-permit-heading {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: $font-weight-extra-bold;
  text-transform: uppercase;
  color: $primary;
  letter-spacing: 1.25px;
  line-height: 1em;
}

.gw__results-permit-text {
  font-size: 30px;
  font-weight: $font-weight-extra-bold;
  line-height: 41px;
  margin: 0;
}

.gw__results-permit-description {
  font-size: 16px;
  font-weight: $font-weight-light;
  line-height: 22px;
}

.gw__results-processing-time {
  color: $black;
  font-size: 45px;
  font-weight: $font-weight-extra-bold;
  line-height: 61px;
}

.gw__results-document-bar {
  height: 10px;
  color: $white;
  background-color: $green;
}

.gw_results-in-country-processing-time {
  height: 10px;
  color: $white;
  background-color: #16942d;
}

.gw__results-government-bar {
  height: 10px;
  background-color: $dark-green;
}

.gw__results-visa-bar {
  height: 10px;
  background-color: $darkest-green;
}

.gw__results-processing-time-weeks {
  font-weight: $font-weight-extra-bold;
  font-size: 18px;
  margin-top: 1em;
  margin-bottom: 0.8em;
}

.gw__results-processing-time-subtext {
  font-size: 14px;
  font-weight: $font-weight-light;
  line-height: 19px;
  margin-top: 0.8em;
}

.gw__results-start-date-wrapper {
  min-height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(60px + 140px + 100px + 80px + 35px);
  // padding-left: 15px;  // match col padding
}

.gw__results-start-date-container {
  display: flex;
  position: relative;
  padding: 0 30px;
}

.gw__results-start-date__arrow {
  position: absolute;
  top: 65px;
  left: 0;
  height: 2px;
  width: 100%;
  border: 1px dashed $green;

  &::before {
    content: '';
    position: absolute;
    left: -2px;
    top: -8px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $green;
    z-index: 0;
  }

  &::after {
    content: '';
    position: absolute;
    right: -6px;
    top: -9px;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-left: 16px solid $green;
  }
}

.gw__results-start-date-box {
  height: 100px;
  width: 100px;
  border: 2px solid $green;
  background-color: $white;
  position: relative;
  margin: 20px;
  display: inline-block;
  color: $green;
  z-index: 1;

  .gw__results-start-date-box__corner {
    position: absolute;
    bottom: -2px;
    right: -2px;
    border-width: 18px 18px 0 0;
    border-style: solid;
    border-color: $green $white;

    &::after {
      content: '';
      position: absolute;
      width: 0;
      top: -16px;
      left: 2px;
      border-width: 11px 11px 0 0;
      border-style: solid;
      border-color: $white transparent;
    }
  }

  &:not(:first-of-type) {
    margin-top: 35px;
  }

  &:first-of-type {
    height: 140px;
    width: 140px;
    border-color: $black;
    color: $black;

    .gw__results-start-date-box__corner {
      border-width: 24px 24px 0 0;
      border-color: $black $white;

      &::after {
        top: -22px;
        border-width: 17px 17px 0 0;
      }
    }

    .gw__results-start-date-month {
      height: 45px;
      line-height: 45px;
      font-size: 14px;
      border-color: $black;
      background-color: $green;
    }

    .gw__results-start-date-day {
      height: 95px;
      line-height: 95px;
      font-size: 32px;
    }
  }
}

.gw__results-start-date-recommended {
  width: 100%;
  color: $black;
  font-weight: $font-weight-extra-bold;
  font-size: 12px;
  position: absolute;
  top: -20px;
  width: 100%;
  text-align: center;
}

.gw__results-start-date-month {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: $font-weight-extra-bold;
  letter-spacing: 0.8px;
  height: 30px;
  line-height: 30px;
  border-bottom: 2px solid $green;
  text-align: center;
}

.gw__results-start-date-day {
  font-size: 24px;
  line-height: 70px;
  font-weight: $font-weight-extra-bold;
  text-align: center;
}

.gw__results-start-date-button {
  background-color: $green;
  text-align: center;
  height: 60px;
  width: 178px;
  box-shadow: 0 6px 14px 0 rgba(141,222,67,0.45);
  font-size: 16px;
  font-weight: $font-weight-bold;
  line-height: 22px;

  &.disabled {
    opacity: 0.42;
    box-shadow: 0 6px 14px 0 rgba(141,222,67,0.45);
  }
}

// Top of the date picker toolbar
.MuiToolbar-root.MuiToolbar-regular.MuiPickersToolbar-toolbar.MuiPickersDatePickerRoot-toolbar {
  background-color: $green;
}

.MuiButtonBase-root {
  // The buttons at the end of the date picker
  &.MuiButton-text {
    &.MuiButton-textPrimary {
      color: $green;
    }
  }
  
  &.MuiIconButton-root {
    &.MuiPickersDay-day {
      // A selected date but not the date which was initially selected
      &.MuiPickersDay-daySelected {
        background-color: $green;
      }
  
      // The initially selcted date
      &.MuiPickersDay-current {
        color: $green;
  
        &.MuiPickersDay-daySelected {
          color: $white;
          background-color: $green;
        }
      }
    }
  }
}

.gw__results-date-picker-container {
  margin-top: $space80;
}

.gw__results-date-picker {
  // The focus outline
  .MuiInputBase-root, .MuiInputAdornment-root, .MuiIconButton-label {
    display: inline-block;
    &:focus {
      outline: none;
    }
  }

  // Fix the button width to the Datepicker label
  .MuiIconButton-label {
    .btn.btn-primary {
      display: flex;
      align-items: center;
      width: 100%;
      height: 58px; // this height is slightly smaller than the usual 70px due to additional padding from the date picker component
    } 
  }

  &.MuiFormControl-root {
    display: inline-block;
  }
  // Remove the automatic margin from the Datepicker
  .MuiInputAdornment-positionEnd {
    margin-left: 0;
    display: block;
  }

  // DatePicker button
  .MuiIconButton-root {
    padding: 0;

    &:hover {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  // Input field for the date picker
  .MuiInputBase-input {
    display: none;
  }

  // Ripple effect when clicking on the date picker button
  .MuiTouchRipple-root {
    display: none;
  }

  .MuiInput-underline {
    // Regular border
    &:before {
      border-bottom: 0;
    }

    // Error border
    &.Mui-error {
      &:after {
        border-bottom: 0;
      }
    }

    &:hover {
      &:not(.Mui-disabled) {
        &:before {
          border: 0;
        }
      }
    }
  }

  // Error text
  .MuiFormHelperText-root {
    &.Mui-error {
      display: none;
    }
  }
}

.gw__results-sections-icons {
  font-size: 30px;
  color: $gray;
}

.gw__results-sections-title {
  font-size: 30px;
  font-weight: $font-weight-extra-bold;
  line-height: 41px;
  margin: 0;
  color: $black;

  &.gw__results-sections-title-highlighted {
    color: $green;
    text-transform: uppercase;
  }
}

.gw__results-sections-content {
  a {
    text-decoration: none;
    color: $green;
    cursor: pointer;
  }
  
  a:hover {
    color: $green-hover;
  }
}

.MuiExpansionPanelDetails-root {
  border-left: none;
  border-right: none;
}

@media screen and (max-width: 991px) {
  .gw__results-success,
  .gw__results-failure {
    display: none;
  }

  .gw__results-assessment-id {
    font-size: 12px;
  }

  .gw__results-title {
    font-size: 26px;
    line-height: 35px;
  }

  .gw__results-subtext {
    font-size: 12px;
  }

  .gw__results__permit-title {
    font-size: 26px;
  }

  .gw__results-permit-heading {
    font-size: 14px;
  }

  .gw__results-permit-text {
    font-size: 13px;
    line-height: 15px;
  }

  .gw__results-permit-description {
    font-size: 13px;
    line-height: 15px;
  }

  .gw__results-processing-time-container {
    display: block;
  }

  .gw__results-processing-time {
    font-size: 22px;
    line-height: 15px;
  }

  .gw__results-processing-time {
    text-align: left;
  }

  div.gw__results-progress-bar-container {
    display: none !important; // TODO - REMOVE WHEN REFACTORING, TEMPORARY FIX
  }

  .gw__results-start-date-wrapper {
    transform: scale(.8);
    transform-origin: left;
    width: calc(60px + 140px + 100px + 40px);
  }

  // .gw__results-start-date-container {
  //   transform: scale(.8);
  //   transform-origin: left;
  // }

  .gw__results-start-date-box {
    margin-bottom: 25px;
  }

  .gw__results-sections-icon {
    font-size: 16px;
  }

  .gw__results-sections-title {
    font-size: 16px;
    line-height: 20px;
  }
}