.gw-announcement {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 4rem;
  font-size: 14px;
  box-shadow: 0px 0px 5px 0px grey;
  z-index: 1;
  transition: background-color 0.3s ease-in-out;
  min-height: 45px;
  display: flex;
  align-items: center;

  .carousel {
    width: 80%;
  }

  .carousel-control-prev,
  .carousel-control-next {
    font-size: calc(1.275rem + 0.3vw);
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 1.25rem;
    color: inherit;
    cursor: pointer;
    font-weight: lighter;
    opacity: 1;
    text-shadow: none;
    font-size: 32px;
    top: 50%;
    transform: translateY(-50%);

    &:hover {
      color: inherit;
    }

    &:focus {
      outline: none;
    }
  }
}

.gw-announcement__item {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.3em;
}

.gw-announcement__icon {
  font-size: 20px;
  padding-right: 14px;

  i {
    font-size: 28px;
  }
}

.gw-announcement__title {
  text-transform: uppercase;
}

.gw__announcement-text {
  font-family: $standard;
  font-weight: 300;
  text-align: left;
  font-size: large;
}

.gw-announcement__text > p {
  margin:auto;
}

@media screen and (max-width: 991px) {
  .gw-announcement {
    padding-left: 0rem;
    padding-right: 2rem;

    & .carousel {
      width: 90%;
    }
  }
}
