.gw__navbar__wrapper {
  z-index: $zindex-fixed;
  top: 0;
}

.navbar {
  box-sizing: border-box;
  height: $navHeight;
  border-bottom: 1px solid #DEDEDE;
  background-color: #FFFFFF;
  box-shadow: -6px 4px 20px -6px rgba(188,188,188,0.41);
  padding: 0 3rem;
  
  .gw__navbar {
    width: 100%;
  }

  .gw__navbar-logo {
    font-size: 20px;
    font-weight: $font-weight-bold;
    line-height: 33px;
    cursor: pointer;
    margin: 0;
  
    // The bolded 'Go'
    .gw__landing-page-title-go {
      font-weight: $font-weight-bold;
    }
  
    // The lightly bolded 'Work'
    .gw__landing-page-title-work {
      font-weight: $font-weight-light;
    }
  }

  .gw__sub-items {
    height: 19px;
    color: $navbar-gray;
    font-size: 14px;
    font-weight: $font-weight-light;
    line-height: 19px;
    cursor: pointer;
    margin: 0;

    &__icon {
      font-size: 1.86em;
      padding-right: 6px;
    }

    &:hover {
      color: darken($navbar-gray, 15%);
    }
  }

  .gw__logout-button {
    color: $green;
    cursor: pointer;
    height: auto;
    font-size: 14px;
    font-weight: normal;
    padding: 0;
    letter-spacing: normal;

    &:focus, &:hover {
      color: $green-hover;
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 991px) {
  .navbar {
    padding: 10px 20px;
    width: 100vw;
    height: $navHeightMobile;
  
    .gw__navbar-logo {
      height: 100%;
      width: 100%;
      font-size: 14px;
      font-weight: $font-weight-bold;
      line-height: 33px;
      cursor: pointer;
    
      // The bolded 'Go'
      .gw__landing-page-title-go {
        font-weight: $font-weight-bold;
      }
    
      // The lightly bolded 'Work'
      .gw__landing-page-title-work {
        font-weight: $font-weight-light;
      }
    }
  
    .gw__sub-items {
      font-size: 14px;
    }
  }
}

@media print {
  .gw__navbar__wrapper {
    position: static !important;
  }
}