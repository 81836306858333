$input-font-size: 32px;
$input-font-size-mobile: 22px;

.gw__assessment__wrapper {
  min-height: inherit;
}

.gw__assessment {
  height: 100%;
  min-height: inherit;
}

.gw__question____existingvisa__wrap {
  overflow: hidden;
}

.gw__question__wrapper {
  position: relative;
  width: 100%;
  min-height: 280px;
}

.gw__question--back {
  &-enter {
    -webkit-transform: translate3d(0, -140%, 0); 
    -ms-transform: translate3d(0, -140%, 0);
    transform: translate3d(0, -140%, 0);
    position: relative;
    will-change: transform;
  }
  &-enter-active {
    -webkit-transform: translate3d(0, 0, 0); 
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition: transform 500ms;
    position: relative;
  }
  &-enter-done {
    -webkit-transform: translate3d(0, 0, 0); 
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    position: relative;
    will-change: unset;
  }
  &-exit {
    -webkit-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
    position: absolute;
    left: 50%;
    top: 0;
    will-change: transform;
  }
  &-exit-active {
    -webkit-transform: translate3d(-50%, 140%, 0);
    -ms-transform: translate3d(-50%, 140%, 0);
    transform: translate3d(-50%, 140%, 0);
    transition: transform 500ms;
    position: absolute;
  }
  &-exit-done {
    -webkit-transform: translate3d(-50%, 140%, 0);
    -ms-transform: translate3d(-50%, 140%, 0);
    transform: translate3d(-50%, 140%, 0);
    position: absolute;
  }
}

.gw__question {
  &-enter {
    -webkit-transform: translate3d(0, 140%, 0); 
    -ms-transform: translate3d(0, 140%, 0);
    transform: translate3d(0, 140%, 0);
    position: relative;
    will-change: transform;
  }
  &-enter-active {
    -webkit-transform: translate3d(0, 0, 0); 
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition: transform 500ms;
    position: relative;
  }
  &-enter-done {
    -webkit-transform: translate3d(0, 0, 0); 
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    position: relative;
    will-change: unset;
  }
  &-exit {
    -webkit-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
    position: absolute;
    left: 50%;
    top: 0;
    will-change: transform;
  }
  &-exit-active {
    -webkit-transform: translate3d(-50%, -140%, 0);
    -ms-transform: translate3d(-50%, -140%, 0);
    transform: translate3d(-50%, -140%, 0);
    transition: transform 500ms;
    position: absolute;
  }
  &-exit-done {
    -webkit-transform: translate3d(-50%, -140%, 0);
    -ms-transform: translate3d(-50%, -140%, 0);
    transform: translate3d(-50%, -140%, 0);
    position: absolute;
  }
}

.gw__assessment-container {
  width: 600px;
  min-height: 280px;
  margin: auto;
}

.gw__assessment-question {
  font-size: 40px;
  font-weight: $font-weight-extra-bold;
  letter-spacing: -1.13px;
  line-height: 50px;
  margin: 0;
}

.gw__assessment-description {
  font-size: 20px;
  font-weight: 300;
  width: 85%;
}

.gw__assessment-description-link {
  text-decoration: none;
  color: $green;
  cursor: pointer;
}

.gw__button-group {
  display: flex;
  margin-top: 3rem;
  width: 600px;
}

.gw__requirement-react-select-container {
  box-shadow: none;
  border-radius: 0;
  height: 4rem;
  width: 100%;
}

.gw__requirement-react-select__placeholder {
  font-size: $input-font-size;
  font-weight: 500;
  font-weight: $font-weight-extra-bold;
  color: $light-gray !important;
  white-space: nowrap;
}

// The selected value which appears in the dropdown
.gw__requirement-react-select__single-value {
    font-size: $input-font-size;
    font-weight: $font-weight-extra-bold;
    color: $dark-gray;
    padding-right: 3rem;
}

// The input bar and typing inside
.gw__requirement-react-select__input {
  font-size: $input-font-size;
  font-weight: $font-weight-light;
  height: 3rem;
  color: $dark-gray;
  display: flex !important; // !important needed here as react-select adds a inline style to the 
  align-items: center;
  box-shadow: none;

  input {
    font-size: $input-font-size;
    font-weight: $font-weight-bold;
  }
}

// The border around the react-select input
.gw__requirement-react-select__control {
  &.gw__requirement-react-select__control--is-disabled {
    color: $gray;
    border-radius: 0;
    border: 0;
  }

  &.css-yk16xz-control {
    box-shadow: none;
    border: none;
    height: 3rem;
  }

  &.gw__requirement-react-select__control--is-focused.css-1pahdxg-control,
  &.gw__requirement-react-select__control--is-focused.gw__requirement-react-select__control--menu-is-open.css-1pahdxg-control {
    border: none;
    box-shadow: none;
  }
}

.gw__requirement-react-select__menu {
  border: 1px solid $lighter-gray !important;
  border-radius: 0 !important;
  border-top: 0 !important;
  margin-top: -2px !important;

  & .gw__requirement-react-select__menu-list {
    padding: 0;
    max-height: 245px !important;

    & .gw__requirement-react-select__menu-notice {
      text-align: left;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      font-size: 14px;

      &.gw__requirement-react-select__menu-notice--no-options {
        color: $red;
      }
    }
  }
}

// The Lock icon
.MuiSvgIcon-root {
  &.gw__requirement-question-lock {
    color: $gray;
    margin-top: 3rem;
    font-size: 18px;
  }
}

.gw__requirement-react-select__indicators {
  position: absolute;
  right: 0;
}

.gw__requirement-react-select__value-container {
  border-bottom: 1px solid $light-gray;
  padding-right: 4rem;
}
.gw__requirement-react-select--is-disabled {
  .gw__requirement-react-select__value-container--has-value {
    border-bottom: 2px solid $gray !important;
  }
}

.gw__requirement-react-select__value-container--has-value {
  border-bottom: 2px solid $green;
}

.gw__requirement-react-select__indicator {
  font-size: $input-font-size;

  .gw__requirement-select-icon {
    color: $green;
    transition: transform 0.15s ease-in-out;
  }
}

.gw__requirement-react-select__control--menu-is-open {
  .gw__requirement-react-select__indicator {
    .gw__requirement-select-icon {
      transform: rotate3d(0, 0, 1, 180deg);
    }
  }
}

// The line at the right hand side of the react-select input
.gw__requirement-react-select__indicator-separator {
  display: none;
}

// The list of options in the dropdown
.gw__requirement-react-select__option {
  font-weight: $font-weight-extra-bold;
  font-size: $input-font-size !important;
  color: $light-gray !important;

  // The value you are focused on in the dropdown
  &.gw__requirement-react-select__option--is-focused {
    background-color: $lighter-gray !important; // Needs to be !important as an additional class is placed on to the option when a click happens, which we cannot see due to the nature of react-select
    color: $dark-gray !important;
  }

  // The selected value in the dropdown
  &.gw__requirement-react-select__option--is-selected {
    background-color: $white;
    color: $dark-gray !important;
  }
}

@media screen and (max-width: 991px) {

  .gw__assessment-container {
    width: 500px;
    margin-top: 0;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 200px;
  }

  .gw__question__wrapper {
    min-height: 200px;
  }

  .gw__button-group {
    width: 500px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 2rem;
  }

  .gw__assessment-question {
    font-size: 24px;
    line-height: 25px;
    letter-spacing: 0;
  }

  .gw__assessment-description {
    font-size: 16px;
  }

  .gw__requirement-react-select-container {
    max-width: 100%;
  }

  .gw__requirement-react-select__indicator {
    font-size: $input-font-size-mobile;
  }
  
  .gw__requirement-react-select__placeholder {
    font-size: $input-font-size-mobile;
    white-space: nowrap;
  }
  
  // The selected value which appears in the dropdown
  .gw__requirement-react-select__single-value {
    font-size: $input-font-size-mobile;
  }
  
  // The input bar and typing inside
  .gw__requirement-react-select__input {
    font-size: $input-font-size-mobile;
  
    input {
      font-size: $input-font-size-mobile;
    }
  }

  // The list of options in the dropdown
  .gw__requirement-react-select__option {
    font-size: $input-font-size-mobile !important;
  }
}

@media screen and (max-width: 575px) {

  .gw__assessment-container {
    width: 100%;
  }

  .gw__button-group {
    width: 100%;
  }

  .gw__question--back {
    &-exit {
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      left: 0;
    }
    &-exit-active {
      -webkit-transform: translate3d(0, 140%, 0);
      -ms-transform: translate3d(0, 140%, 0);
      transform: translate3d(0, 140%, 0);
    }
    &-exit-done {
      -webkit-transform: translate3d(0, 140%, 0);
      -ms-transform: translate3d(0, 140%, 0);
      transform: translate3d(0, 140%, 0);
    }
  }
  
  .gw__question {
    &-exit {
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      left: 0;
    }
    &-exit-active {
      -webkit-transform: translate3d(0, -140%, 0);
      -ms-transform: translate3d(0, -140%, 0);
      transform: translate3d(0, -140%, 0);
    }
    &-exit-done {
      -webkit-transform: translate3d(0, -140%, 0);
      -ms-transform: translate3d(0, -140%, 0);
      transform: translate3d(0, -140%, 0);
    }
  }
}
