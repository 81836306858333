.gw__horizonal-stepper {
  height: 0.4rem;
  width: 25%;
  background-color: $green;
}

.gw__horizonal-stepper-container {
  height: 0.4rem;
  position: fixed;
  top: 0rem;
  z-index: 1300;

  &.gw__cover {
    z-index: 1400;
    background-color: $white;
    right: 0rem;
  }
}
